import { useEffect, useState } from 'react';
import './App.css';

function App() {
  const servers = ['srv5']
  const serverNames = ['Nitro']
  const serverLimits = [100, 100]

  const [view, setView] = useState("login")
  const [username, setUsername] = useState("");
  const [userData, setUserData] = useState(0);  
  const [progress, setProgress] = useState(0);  
  const [serversArray, setServersArray] = useState([]);
  const [instQueue, setInstQueue] = useState('');

  async function fetchWithTimeout(resource, options = {}) {
    const { timeout = 10000 } = options;
    
    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), timeout);
    const response = await fetch(resource, {
      ...options,
      signal: controller.signal  
    });

    clearTimeout(id);
    return response;
  }

  async function handleSubmit(evt) {
    evt.preventDefault();
    document.cookie = `username=${username}; expires=${new Date("31 December 5000").toUTCString()}; path=/`;
    await refresh();
  }

  async function refresh() {
    setView("loading")
    servers.forEach((srv) => setUserData((prev) => ({ ...prev, [srv]: null })));

    servers.forEach((srv) => {
      fetchWithTimeout(`https://${srv}.nitrous.dev:1000/api/${username}`)
        .then((response) => response.json())
        .then((data) => { 
          setUserData((prev) => ({ ...prev, [srv]: data }));
        })
        .catch((err) => {

        })
        .finally(() => {
          setProgress((prev) => { return ++prev; })
        });
    })
  }

  useEffect(() => {
    if (progress < servers.length) return;
    setProgress(0);

    servers.forEach((srv) => {
      if (userData[srv] === null) userData[srv] = { rem: -1 };
      else if (userData[srv].quota === "-1") userData[srv].rem = 9999;
      else userData[srv].rem = Math.max(0, (parseFloat(((parseFloat(userData[srv].quota) 
        - parseFloat(userData[srv].download) - parseFloat(userData[srv].upload))
        / (1024 * 1024 * 1024))))).toFixed(1)
    })

    let oneWorks = false;
    servers.forEach((srv) => {
      if (userData[srv].rem != -1) oneWorks = true;
    })

    if (oneWorks === false) {
      setView('failed');
      return;
    }

    setServersArray(servers.map((srv, idx) => 
        <div className='result-server' key={srv}>
          <p><span className='whiteSpan'>{serverNames[idx]}</span> — <span className='whiteSpan'>
            {(userData[srv].rem === 9999) ? "Unlimited" : (userData[srv].rem === -1) ? "Unavailable" : `${userData[srv].rem}GB`}
          </span></p>
          <div className='bar gradient-border'>
            <div className='bar-fill' style={{ clipPath: 
              `polygon(-50px -50px, ${(userData[srv].rem / serverLimits[idx]) * 100}% -50px, ${(userData[srv].rem / serverLimits[idx]) * 100}% 150%, -50px 150%)` 
            }}></div>
          </div>
        </div>
    ))
    setView("results")

  }, [ progress ])

  function logout() {
    document.cookie = `username=${username}; expires=${new Date().toUTCString()}; path=/`;
    setUsername("");
    setView('login')
  }

  useEffect(() => {
    let cookie = document.cookie
    if (cookie.length != 0) {
      setUsername(cookie.split("=")[1])
      setInstQueue('refresh-cookie')
    }
  }, [])

  useEffect(() => {
    if (instQueue === 'refresh-cookie') {
      setInstQueue('');
      refresh();
    }
  }, [username, instQueue])

  return (
    <div className="App">
      <div className='box'>
        <div className='gradient-border'>
          <form onSubmit={handleSubmit} style={{ display: (view === "login") ? "block" : "none" }} className='view-login'>
            <img src='./logo.png' className='logo'/>
            <p>Welcome to Nitrous VPN</p>
            <div className='gradient-border b2'>
              <input value={username} onChange={(evt) => { setUsername(evt.target.value) }} placeholder="Username" />
            </div>
            <button type='submit'><p>Login</p></button>
          </form>
          <div style={{ display: (view === "loading") ? "block" : "none" }} className='view-loading'>
            <div className="lds-ring">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
            <div className='bar gradient-border loading' style={{ width:"85%", margin: "2em auto 0 auto" }}>
              <div className='bar-fill' style={{ clipPath: 
                `polygon(-50px -50px, ${(progress / servers.length) * 100}% -50px, ${(progress / servers.length) * 100}% 150%, -50px 150%)` 
              }}></div>
            </div>
            <p className='progress-indicator'>{progress * (100 / servers.length)}%</p>
          </div>
          <div style={{ display: (view === "results") ? "block" : "none" }} className='view-results'>
            <p className='resultsTitle'>Remaining Quota</p>
            {serversArray}
            <div className='results-buttons'>
              <button onClick={refresh}><p>Refresh</p></button>
              <button onClick={logout}><p>Logout</p></button>
            </div>
          </div>
          <div style={{ display: (view === "failed") ? "block" : "none" }} className='view-results'>
            <p className='resultsTitle'>No User Data Found!</p>
            <div className='results-buttons' style={{ justifyContent: "center", transformOrigin: "center center" }}>
              <button onClick={refresh} style={{ transformOrigin: "center center" }}><p>Retry</p></button>
              <button onClick={logout} style={{ transformOrigin: "center center" }}><p>Logout</p></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
